import React, {useState} from 'react'
import { Link } from 'gatsby'
import axios from 'axios'
import './CallbackForm.scss'

function CallbackForm(props) {
  const url = '/api/telegram.php'
  const nameForm = props.formName;
  const [data, setData] = useState({
    formName: "",
    name: "",
    phone: "",
    message: ""
  })

  function submit(e){
    e.preventDefault();
    axios.post(url, {
      'form_name': nameForm,
      'user_name': data.name,
      'user_phone': data.phone,
      'user_message': data.message
    })
    .then(function () {
      setData({
        name: "",
        phone: "",
        message: ""
     })
      alert('Спасибо за заявку! Мы скоро с Вами свяжемся.')
    });
  }

  function handle(e){
    const newData = {...data}
    newData[e.target.id] = e.target.value
    setData(newData)
  }

  return (
    <div className="callbackForm">
      <h3>{props.title}</h3>
      <form onSubmit={(e)=>submit(e)}>
        <div className="formInput">
          <div className="formGroup">
            <input onChange={(e)=>handle(e)} value={data.name} id="name" type="text" placeholder="Имя" name="name" autocomplete="off" required></input>
          </div>
          <div className="formGroup">
            <input onChange={(e)=>handle(e)} value={data.phone} id="phone" type="tel" placeholder="Телефон" name="phone" autocomplete="off" required></input>
          </div>
          <div className="formGroup">
            <textarea onChange={(e)=>handle(e)} value={data.message} id="message" placeholder={props.message} name="message" maxLength="200" rows="5"></textarea>
          </div>
        </div>
        <p className="politics">Оставляя заявку Вы соглашаетесь<br />с <Link to="/policy/">политикой конфиденциальности</Link></p>
        <button type="submit" className="submitButton">{props.button}</button>
      </form>
    </div>
  )
}

export default CallbackForm
