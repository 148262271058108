import React from 'react'
import PropTypes from "prop-types"
import { motion } from "framer-motion"

function Layout({children, styleName}) {
  return (
    <motion.main className={styleName}
      initial={{
        opacity: 0,
        x: -15,
      }}
      animate={{
        opacity: 1,
        x: 0,
      }}
      exit={{
        opacity: 0,
        x: 15,
      }}
      transition={{
        type: "spring",
        mass: 0.35,
        stiffness: 75,
        duration: 0.6
      }}>
      {children}
    </motion.main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
